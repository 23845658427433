import React from 'react';
import '../assets/style/style.scss';
import 'semantic-ui-css/semantic.min.css'
import { Table } from 'semantic-ui-react'
import Navbar from '../components/navbar';

const InfoConnexion = () => {

    return (
        <div>
            <Navbar >
                <div className='container-fluid'>
                    <div className='row'>
                        <h2 className='titre mt-4 text-center'>Information de connexion</h2>
                    </div>
                    <div className='row gx-2 text-center'>
                        <div className='col-12 col-md-4'>
                            <div>
                                <h3 className='tableAppointmment text-white'>Thomas</h3>
                                <div className='border'>
                                    <h3>Sévigné</h3>
                                    <p>Identifiant : DAUREL THOMAS</p>
                                    <p>Mot de passe : sMwB5QJ7</p>
                                    <a href="http://wikisev.sevigne-compiegne.fr/">Acces</a>
                                </div>
                                <div className='border mt-1'>
                                    <h3>Pronote</h3>
                                    <p>Identifiant : DAUREL THOMAS</p>
                                    <p>Mot de passe : Hayatek60150!</p>
                                    <a href="https://0601501f.index-education.net/pronote/">Acces</a>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <h3 className='tableAppointmment text-white'>Prescilya</h3>
                        </div>
                        <div className='col-12 col-md-4'>
                            <h3 className='tableAppointmment text-white'>Enfant</h3>
                            <div className='border'>
                                <h3>Sévigné April</h3>
                                <p>Identifiant : ADAUREL</p>
                                <p>Mot de passe : 67t5vQ34</p>
                                <a href="http://wikisev.sevigne-compiegne.fr/">Acces</a>
                            </div>
                            <div className='border mt-1'>
                                <h3>Sévigné Anna</h3>
                                <p>Identifiant : ADAUREL1</p>
                                <p>Mot de passe : wzgYv2RP</p>
                                <a href="http://wikisev.sevigne-compiegne.fr/">Acces</a>
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}

export default InfoConnexion;
