import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'semantic-ui-css/semantic.min.css';
import { Table, Modal, Button, Form } from 'semantic-ui-react';
import Navbar from '../components/navbar';
import '../assets/style/style.scss';
import 'react-datepicker/dist/react-datepicker.css';

const Home = () => {
    const [appointments, setAppointments] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedPerson, setSelectedPerson] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [locationValue, setLocationValue] = useState('');
    const [infosValue, setInfosValue] = useState('');

    useEffect(() => {
        axios.get('https://api.daurelthomas.fr/api/appointments')
            .then(response => {
                const transformedAppointments = response.data.map(appointment => ({
                    ...appointment,
                    date: new Date(appointment.date)
                }));

                transformedAppointments.sort((a, b) => a.date - b.date);

                setAppointments(transformedAppointments);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des rendez-vous :', error);
            });
    }, []);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const addAppointment = () => {
        const newAppointmentData = {
            creator: selectedPerson,
            date: selectedDate,
            description: descriptionValue,
            location: locationValue,
            infos: infosValue,
        };
    
        axios.post('https://api.daurelthomas.fr/api/appointments', newAppointmentData)
            .then(response => {
                const updatedAppointments = [...appointments, response.data];
                updatedAppointments.sort((a, b) => a.date - b.date); // Tri des rendez-vous par date
                setAppointments(updatedAppointments);
                closeModal();
            })
            .catch(error => {
                console.error('Erreur lors de l\'ajout du rendez-vous :', error);
            });
    };

    const deleteAppointment = (id) => {
        axios.delete(`https://api.daurelthomas.fr/api/appointments/${id}`)
            .then(response => {
                const updatedAppointments = appointments.filter(appointment => appointment.id !== id);
                setAppointments(updatedAppointments);
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du rendez-vous :', error);
            });
    };

    return (
        <div>
            <Navbar>
                <div className='container-fluide'>
                    <div className='row'>
                        <div className='col-12 col-md-10 text-center offset-md-1'>
                            <div>
                                <h1 className='mt-4 text-center titre'>Bienvenue sur le Dashboard de la famille Daurel</h1>
                                <p className='text-danger'>Cet espace est privé et ne peut être visité que par des personnes autorisées.</p>
                                <button className="positive ui button" onClick={openModal}>Ajouter un rendez-vous</button>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 col-md-10 offset-md-1'>
                            <div className='mt-3 ms-2'>
                                <Table basic>
                                    <Table.Header className='tableAppointmment'>
                                        <Table.Row>
                                            <Table.HeaderCell className='text-white fs-4 text-center'><i className="me-3 fa-solid fa-children"></i>Personne</Table.HeaderCell>
                                            <Table.HeaderCell className='text-white fs-4 text-center'><i className="me-3 fa-solid fa-calendar-days"></i>Date</Table.HeaderCell>
                                            <Table.HeaderCell className='text-white fs-4 text-center'><i className="me-3 fa-regular fa-message"></i>Description</Table.HeaderCell>
                                            <Table.HeaderCell className='text-white fs-4 text-center'><i className="me-3 fa-solid fa-location-dot"></i>Lieu</Table.HeaderCell>
                                            <Table.HeaderCell className='text-white fs-4 text-center'><i className="me-3 fa-solid fa-circle-question"></i>Infos</Table.HeaderCell>
                                            <Table.HeaderCell className='text-white fs-4 text-center'><i className="me-3 fa-solid fa-circle-question"></i>Modifications</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {appointments.map(appointment => (
                                            <Table.Row className='text-center' key={appointment.id}>
                                                <Table.Cell>{appointment.creator}</Table.Cell>
                                                <Table.Cell>
                                                    {new Date(appointment.date).toLocaleDateString('fr-FR', {
                                                        year: 'numeric',
                                                        month: 'long',
                                                        day: 'numeric',
                                                    })}
                                                </Table.Cell>
                                                <Table.Cell>{appointment.description}</Table.Cell>
                                                <Table.Cell>{appointment.location}</Table.Cell>
                                                <Table.Cell>{appointment.infos}</Table.Cell>
                                                <Table.Cell>
                                                    <button className='border border-0 bg-white'><i className="fa-solid fa-pen-to-square text-success fa-xl"></i></button>
                                                    <button className='border border-0 bg-white' onClick={() => deleteAppointment(appointment.id)}>
                                                        <i className="fa-solid fa-trash text-danger fa-xl"></i>
                                                    </button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
            <Modal open={showModal} onClose={closeModal} size='mini' className='custom-centered-modal'>
                <Modal.Header className='text-center'>Ajouter un rendez-vous</Modal.Header>
                <Modal.Content>
                    <div className='d-flex align-items-center flex-column'>
                        <select
                            className="ui dropdown w-100 text-center"
                            value={selectedPerson}
                            onChange={(e) => setSelectedPerson(e.target.value)}
                        >
                            <option value="">Personne</option>
                            <option value="Thomas">Thomas</option>
                            <option value="Prescilya">Prescilya</option>
                            <option value="April">April</option>
                            <option value="Anna">Anna</option>
                        </select>
                        <div>
                            <input
                                type="date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                                className='elementForm'
                                label='Date'
                                name="firstDateCurculate" />
                        </div>
                        <div class="ui form">
                            <div class="field">
                                <textarea
                                    placeholder='Description'
                                    value={descriptionValue}
                                    onChange={(e) => setDescriptionValue(e.target.value)}
                                ></textarea>
                            </div>
                            <div class="field">
                                <textarea
                                    placeholder='Lieu'
                                    rows="2"
                                    value={locationValue}
                                    onChange={(e) => setLocationValue(e.target.value)}
                                ></textarea>
                            </div>
                            <div class="field">
                                <textarea
                                    placeholder='Infos'
                                    rows="2"
                                    value={infosValue}
                                    onChange={(e) => setInfosValue(e.target.value)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={addAppointment} className='w-100 mb-2'>
                        Ajouter
                    </Button>
                    <Button onClick={closeModal} className='w-100 bg-danger '>
                        Annuler
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
}

export default Home;
