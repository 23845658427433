import React, { useEffect, useState } from 'react';
import '../assets/style/style.scss';
import 'semantic-ui-css/semantic.min.css';
import Navbar from '../components/navbar';

const ListCourse1 = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    // Charger la liste des catégories depuis l'API
    useEffect(() => {
        fetch('https://api.daurelthomas.fr/api/listcategories')
            .then((response) => response.json())
            .then((data) => setCategories(data))
            .catch((error) => console.error('Erreur lors du chargement des catégories : ', error));
    }, []);

    // Charger la liste des produits en fonction de la catégorie sélectionnée
    useEffect(() => {
        if (selectedCategory) {
            fetch(`https://api.daurelthomas.fr/api/listproducts/category/${selectedCategory}`)
                .then((response) => response.json())
                .then((data) => setProducts(data))
                .catch((error) => console.error('Erreur lors du chargement des produits : ', error));
        } else {
            // Réinitialiser la liste des produits si aucune catégorie n'est sélectionnée
            setProducts([]);
        }
    }, [selectedCategory]);

    const handleCategoryChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedCategory(selectedValue);
    };

    const handleProductSelection = (productId) => {
        const updatedProducts = products.map((product) => {
            if (product.id === productId) {
                const isSelected = !product.selected;
                const newQuantity = isSelected ? 1 : 0;
                return { ...product, quantity: newQuantity, selected: isSelected };
            }
            return product;
        });

        setProducts(updatedProducts);
    };

    const handleQuantityChange = (productId, increment) => {
        const updatedProducts = products.map((product) => {
            if (product.id === productId) {
                const currentQuantity = product.quantity || 0;
                const newQuantity = currentQuantity + increment;
                const isSelected = newQuantity > 0;
                return { ...product, quantity: isSelected ? newQuantity : 0, selected: isSelected };
            }
            return product;
        });

        setProducts(updatedProducts);
    };

    return (
        <div>
            <Navbar>
                <div className='container-fluid'>
                    <div className='row mt-2'>
                        <div className='col-12 mt-5'>
                            <h2 className='titre text-center'>Liste de course</h2>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center mb-4'>
                            <select onChange={handleCategoryChange} value={selectedCategory}>
                                <option value=''>Sélectionnez une catégorie</option>
                                {categories.map((category) => (
                                    <option key={category.id} value={category.id}>
                                        {category.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex justify-content-center'>
                                <ul>
                                    {products.map((product) => (
                                        <li key={product.id}>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        className='me-2'
                                                        checked={product.selected}
                                                        onChange={() => handleProductSelection(product.id)}
                                                    />
                                                </div>
                                                <div className='flex-grow-1'>
                                                    {product.name.charAt(0).toUpperCase() + product.name.slice(1)}
                                                </div>
                                                <div>
                                                    <button
                                                        className='ms-2 ui button mini red'
                                                        onClick={() => handleQuantityChange(product.id, -1)}
                                                    >
                                                        -
                                                    </button>
                                                    <span className='quantity-control ms-1 me-1'>{product.quantity || 0}</span>
                                                    <button
                                                        className='quantity-control mini ui green button'
                                                        onClick={() => handleQuantityChange(product.id, 1)}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    );
};

export default ListCourse1;
