import React, { useState, useEffect } from 'react';
import './assets/style/style.scss';
import { Link,useNavigate } from 'react-router-dom';

function App() {
  const [loginData, setLoginData] = useState({ username: '', password: '' });
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Your login validation logic here
    if (loginData.username === 'thomas' && loginData.password === 'thomas') {
      setIsLoginSuccess(true);
    } else {
      setIsLoginSuccess(false);
    }
  };

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  // Use useEffect to trigger navigation when isLoginSuccess changes
  useEffect(() => {
    if (isLoginSuccess) {
      navigateToAccueil();
    }
  }, [isLoginSuccess]);

  // useNavigate to navigate to "/accueil"
  const navigate = useNavigate();
  const navigateToAccueil = () => {
    navigate('/accueil');
  };

    return (
      <div className="App">
        <div className="login-page bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="bg-white shadow rounded">
                  <div className="row">
                    <div className="col-md-7 pe-0">
                      <div className="form-left h-100 py-5 px-5">
                        <form className="row g-4" onSubmit={handleSubmit}>
                          <div className="col-12">
                            <label>Username<span className="text-danger">*</span></label>
                            <div className="input-group">
                              <div className="input-group-text"><i className="fa-solid fa-user"></i></div>
                              {/* Update the input value and name attributes */}
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Username"
                                name="username"
                                value={loginData.username}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-12">
                            <label>Password<span className="text-danger">*</span></label>
                            <div className="input-group">
                              <div className="input-group-text"><i className="fa-solid fa-key"></i></div>
                              {/* Update the input value and name attributes */}
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Enter Password"
                                name="password"
                                value={loginData.password}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="form-check">
                              <input className="form-check-input " type="checkbox" id="inlineFormCheck" />
                              <label className="form-check-label" htmlFor="inlineFormCheck">Enregistrer les informations</label>
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <a href="#" className="float-end text-black">Contact</a>
                          </div>
                          <div className="col-12">
                            {isLoginSuccess ? (
                              <Link to="/accueil">
                                <button type="submit" className="text-white btn form-right px-4 float-end mt-4" name="submit">
                                  Login
                                </button>
                              </Link>
                            ) : (
                              <button type="submit" className="text-white btn form-right px-4 float-end mt-4" name="submit">
                                Login
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-5 ps-0 d-none d-md-block">
                      <div className="form-right h-100  text-white text-center pt-5">
                        <i className="bi bi-bootstrap"></i>
                        <h2 className="fs-1">Daurel Family</h2>
                        <p>Espace privé</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  export default App;
