import React from 'react';
import '../assets/style/style.scss';
import 'semantic-ui-css/semantic.min.css'
import { Table } from 'semantic-ui-react'
import Navbar from '../components/navbar';

const PlanningApril = () => {

    return (
        <div>
            <Navbar >
                <div className='container-fluid'>
                    <div className='row mt-2'>
                        <div className='col-12 mt-5'>
                            <h2 className='titre text-center '>Planning patin April</h2>
                        </div>
                        <div className='col-12 col-md-10 offset-md-1 mt-5'>
                            <table class="table  table-bordered text-center">
                                <thead>
                                    <tr>
                                        <th scope="col"><p><span className='bg-success me-2'>Glace </span><span className='bg-warning me-2'>HG</span><span className='bg-primary'>PPG</span></p></th>
                                        <th scope="col">Matin</th>
                                        <th scope="col">Aprés-midi</th>
                                        <th scope="col">Soir</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">Lundi</th>
                                        <td className='bg-success '>7h00/8h30</td>
                                        <td className='bg-success '>12h00/13h45</td>
                                        <td className='bg-warning '>19h00/20h00</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mardi</th>
                                        <td></td>
                                        <td className='bg-success '>12h00/13h45</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Mercredi</th>
                                        <td className='bg-success '>7h00/9h00</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Jeudi</th>
                                        <td className='bg-success '>7h00/8h30</td>
                                        <td></td>
                                        <td className='bg-success '>16h15/17h15</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Vendredi</th>
                                        <td className='bg-success '>7h00/8h30</td>
                                        <td className='bg-success '>12h00/13h45</td>
                                        <td ></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Samedi</th>
                                        <td className='bg-success'>7h00/9h00</td>
                                        <td className='bg-primary'><span className='text-danger'>APRES GLACE </span>9h30-10h30</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Navbar>
        </div>
    )
}

export default PlanningApril;
