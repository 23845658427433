import React, { useState, useEffect } from 'react';
import { Table, Header, Dropdown, Input } from 'semantic-ui-react';
import Navbar from '../components/navbar';
import axios from 'axios';

const Classement = () => {
  const [data, setData] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [searchName, setSearchName] = useState('');
  const [allNames, setAllNames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://docs.google.com/spreadsheets/d/e/2PACX-1vSj31GOuRQ2tTJ_uSQKj2L0Y5cqRceS1fw4GiHOoXVVS01RQs0MGHFqGo-EegCewKHs21ukjmiEEvdj/pub?output=csv'
        );

        const csvData = response.data;

        const lines = csvData.split('\n');
        const headers = lines[0].split(',');
        const result = [];
        const clubSet = new Set();
        const nameSet = new Set();

        for (let i = 1; i < lines.length; i++) {
          const obj = {};
          const currentLine = lines[i].split(',');
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j].trim()] = currentLine[j].trim();
          }
          result.push(obj);
          clubSet.add(obj['Club']);
          nameSet.add(`${obj['Prénom']} ${obj['Nom']}`);
        }

        setClubs(Array.from(clubSet));
        setData(result);
        setAllNames(Array.from(nameSet));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleClubChange = (event, { value }) => {
    setSelectedClub(value);
    setCurrentPage(1); // Réinitialiser la page actuelle lorsqu'un nouveau club est sélectionné
    setClubs(Array.from(new Set(data.map(item => item['Club']))));
  };

  const handleNameSearchChange = (event, { value }) => {
    setSearchName(value);
  };

  const renderMemberRows = () => {
    const filteredData = data.filter(
      item =>
        item['Club'].toLowerCase() === selectedClub.toLowerCase() &&
        `${item['Prénom']} ${item['Nom']}`.toLowerCase().includes(searchName.toLowerCase()) // Filtrer par nom
    );
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems.map((item, index) => (
      <Table.Row key={index}>
        <Table.Cell>{`${item['Prénom']} ${item['Nom']}`}</Table.Cell>
        <Table.Cell>{item['Naissance']}</Table.Cell>
        <Table.Cell>{`${item['Cl. Gal']}ème`}</Table.Cell>
        <Table.Cell>{item['Méd.']}</Table.Cell>
      </Table.Row>
    ));
  };

  const totalPages = Math.ceil(
    data.filter(
      item =>
        item['Club'].toLowerCase() === selectedClub.toLowerCase() &&
        `${item['Prénom']} ${item['Nom']}`.toLowerCase().includes(searchName.toLowerCase()) // Filtrer par nom
    ).length / itemsPerPage
  );

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={currentPage === i ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    return <div className="pagination">{pageNumbers}</div>;
  };

  const getTitle = () => {
    if (selectedClub) {
      return `Classement des membres du club ${selectedClub}`;
    } else {
      return 'Classement des membres du club';
    }
  };

  return (
    <div>
      <Navbar>
        <div className='container-fluid'>
          <div className='row mt-2'>
            <div className='col-12 mt-5'>
              <h2 className='titre text-center'>Saison 2023-2024</h2>
              <Header as='h2' className='titre text-center'>
                {getTitle()}
              </Header>
              <div className='mt-3 offset-md-3 col-md-6'>
                <Dropdown
                  placeholder='Recherchez ou sélectionnez un club'
                  fluid
                  search
                  selection
                  options={clubs.map(club => ({
                    key: club,
                    text: club,
                    value: club
                  }))}
                  onChange={handleClubChange}
                  value={selectedClub}
                />
              </div>
              {selectedClub && (
                <>
                  <div className='mt-3 offset-md-3 col-md-6'>

                    <Input
                      placeholder='Recherchez par nom'
                      fluid
                      search
                      onChange={(event, { value }) => handleNameSearchChange(event, { value })}
                      value={searchName}
                    />
                  </div>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Nom</Table.HeaderCell>
                        <Table.HeaderCell>Date de naissance</Table.HeaderCell>
                        <Table.HeaderCell>Classement</Table.HeaderCell>
                        <Table.HeaderCell>Médaille</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>{renderMemberRows()}</Table.Body>
                  </Table>
                  {totalPages > 1 && renderPagination()}
                </>
              )}
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Classement;
