import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import '../assets/style/style.scss';

const Navbar = ({ children }) => {
    useEffect(() => {
        const mobileScreen = window.matchMedia("(max-width: 990px)");

        function handleDropdownToggle() {
            $(this)
                .closest(".dashboard-nav-dropdown")
                .toggleClass("show")
                .find(".dashboard-nav-dropdown")
                .removeClass("show");
            $(this).parent().siblings().removeClass("show");
        }

        function handleMenuToggle() {
            if (mobileScreen.matches) {
                $(".dashboard-nav").toggleClass("mobile-show");
            } else {
                $(".dashboard").toggleClass("dashboard-compact");
            }
        }

        $(".dashboard-nav-dropdown-toggle").on("click", handleDropdownToggle);
        $(".menu-toggle").on("click", handleMenuToggle);

        return () => {
            // Clean up the event listeners when the component unmounts
            $(".dashboard-nav-dropdown-toggle").off("click", handleDropdownToggle);
            $(".menu-toggle").off("click", handleMenuToggle);
        };
    }, []); // Empty dependency array ensures that the effect runs only once after the component mounts

    return (

        <div class='dashboard'>
            <div class="dashboard-nav">
                <header>
                    <a href="#!" class="menu-toggle"><i class="fas fa-bars"></i></a><Link to="/accueil" class="brand-logo"><i class="fa-brands fa-themeisle"></i> <span>Daurel Family</span></Link>
                </header>

                <nav class="dashboard-nav-list">
                    <Link to="/accueil" class="dashboard-nav-item"><i class="fas fa-home"></i>Accueil </Link>
                    <a href="#" class="dashboard-nav-item"><i class="fas fa-calendar-check"></i> Calendrier</a>
                    <a href="#" class="dashboard-nav-item"><i class="fas fa-file-upload"></i> Document </a>

                    <div class='dashboard-nav-dropdown'>
                        <a href="#!" class="dashboard-nav-item dashboard-nav-dropdown-toggle"><i class="fas fa-chart-bar"></i> Planning </a>
                        <div class='dashboard-nav-dropdown-menu'>
                            <a href="#" class="dashboard-nav-dropdown-item">Papa</a>
                            <a href="#" class="dashboard-nav-dropdown-item">Maman</a>
                            <Link to="/planning-April" class="dashboard-nav-dropdown-item">April</Link>
                            <a href="#" class="dashboard-nav-dropdown-item">Anna</a>
                        </div>
                    </div>

                    <div class='dashboard-nav-dropdown'>
                        <a href="#!" class="dashboard-nav-item dashboard-nav-dropdown-toggle"><i class="fas fa-utensils"></i> Recette </a>
                        <div class='dashboard-nav-dropdown-menu'>
                            <a href="#" class="dashboard-nav-dropdown-item">Entrée</a>
                            <a href="#" class="dashboard-nav-dropdown-item">Plat</a>
                            <a href="#" class="dashboard-nav-dropdown-item">Dessert</a>
                            <a href="#" class="dashboard-nav-dropdown-item">Divers</a>
                        </div>
                    </div>

                    <div class='dashboard-nav-dropdown'>
                        <a href="#!" class="dashboard-nav-item dashboard-nav-dropdown-toggle"><i class="fas fa-money-check-alt"></i> Divers </a>
                        <div class='dashboard-nav-dropdown-menu'>
                            <Link to="/information-connexion" class="dashboard-nav-dropdown-item">Information connexion</Link>
                            <Link to="/classement" class="dashboard-nav-dropdown-item">French Ranking</Link>
                            <a href="#" class="dashboard-nav-dropdown-item">Vide</a>
                        </div>

                    </div>
                    <Link to="/course" class="dashboard-nav-item"><i class="fas fa-cash-register"></i> Liste de course </Link>
                    <a href="/outils" class="dashboard-nav-item"><i class="fas fa-user"></i> Administration </a>
                    <div class="nav-item-divider"></div>
                    <Link to="/" class="dashboard-nav-item"><i class="fas fa-sign-out-alt"></i> Deconnexion </Link>
                </nav>
            </div>
            <div class='dashboard-app'>
                <header class='dashboard-toolbar'><a href="#!" class="menu-toggle"><i class="fas fa-bars"></i></a></header>
                {children} {/* Le contenu dynamique sera affiché ici */}
            </div>

        </div>

    );
};

export default Navbar;